<template>
  <section class="contenedor-columnas">

    <StartComponent />
    <!-- <BackComponent :grand="this.grand" /> -->
    <GrantComponent :grand="this.grand" />
    <MayorComponent :mayor="this.mayor"/>
    <MiniComponent :minor="this.minor"/>
    <EndComponent />


  </section>
</template>

<script>
// @ is an alias to /src

import ProgresiveService from '../services/ProgresiveService';

// import BackComponent from '@/components/backComponent.vue';

import StartComponent from '@/components/StartComponent.vue'
import GrantComponent from '@/components/GrandComponent.vue'
import MayorComponent from '@/components/MayorComponent.vue'
import MiniComponent from '@/components/MiniComponent.vue'
import EndComponent from '@/components/EndComponent.vue'

import Salas from '@/data/salas'

export default {
  name: 'HomeView',
  data(){
    return{
      // idSala:0,
      nombreSala:"",
      progresiveResult: {},
      minor:0.0,
      mayor:0.0,
      grand:0.0
    }
  },
  components: {
    StartComponent,
    GrantComponent,
    MayorComponent,
    MiniComponent,
    EndComponent,
    // BackComponent
  },
  methods:{
    consultaProgresivo() {
      // console.log(item);

      this.nombreSala=this.$route.params.nombreSala
      console.log("NombreSala:::::::::: " + this.nombreSala);

      const idSala= Salas.find(sala => sala.nombre===this.nombreSala).id

        let body = {
        "idSala": idSala
      }
      ProgresiveService.getProgresive(body).then((response) => {

        if (response.status == 200) {
          this.progresiveResult = response.data;

          this.minor=response.data.progresivo.minor;
          this.mayor=response.data.progresivo.mayor;
          this.grand=response.data.progresivo.grand;

          console.log("RESULTADO DE LA PETICION WEB STATUS: " + response.status);
          console.log("minor: " + this.minor);
          console.log("mayor: " + this.mayor);
          console.log("grand: " + this.grand);
        }

      }).catch(err => {


        console.log("err.message: " + err.message);

        // alert("err.message: " + err);

        alert(err.message)
      });
      
    },

  },
  // created() {
  //   this.consultaProgresivo()
  // },
  mounted(){
    this.consultaProgresivo()
  }

}
</script>

<style scoped>


.contenedor-columnas {
  /*Modifica aquí el tamaño de las columnas y veras que no se deforma la imagen ni se recorta*/
  --col-height: 300px;
	width: 100%;
	margin: auto;
	text-align: center;
	margin-bottom:  0px;
  background-color: black;
  
}


</style>