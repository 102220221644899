<template>
  <div class="grand" >
 
    <div class="columnas">
      
      <div class="columnaA">
        <img src="../assets/personajes/VICTORIA_alpha6.png" alt=""> 
        <!-- <img src="../assets/personajes/Hada.png" alt="">  -->
      </div>

      <div class="columna">
        
        <img src="../assets/texts/Grand.png" alt=""> 
        <div class="campoGrand">
          <p>{{ formatCurrency(grand) }}</p>
        </div >
        
      </div>

    </div>
    
  </div>
</template>

<script>
export default {
  name: 'GrandComponent',
  data() {
    return {
      inputValue: '',
      imageUrl:"../assets/boxes/caja_grand.png"
    };
  },
  props: {
    grand: Number
  },
    methods: {
    handleImageChange() {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);

      // this.imageUrl = "../assets/boxes/caja_grand.png";
      },
    formatCurrency(value) {
      return new Intl.NumberFormat('es-Mx', { style: 'currency', currency: 'MXN' }).format(value)
    }
  },
}
</script>


<style scoped>
.grand {
  background-image: url('../assets/fondo/hada_fondo.png');
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  height: var(--col-height);
  /* background-color: red; */
  /* background-color: #ece0d5; */
  /* background-blend-mode: color-burn;Esta propiedad es innecesaria, ignorala. La uso simplemente para darle un tono distinto y más claro al fondo */
	background-position: center;
	background-size: cover;	

}


.grand img {
  width: 100%;      
  height: calc(100% - 50px); 
  object-fit: contain;  
}


.columnas {
  display: flex;
  align-items:center;
  height: 100%;
}



.columna {
  flex: 1 1 100%;
  align-items: center;
  /* margin: 40px; */
}
.columna img {
  display: block;
  margin: 0 auto;
  max-width: 80%;
  height: auto;
}



.columnaA {
  flex: 1 2 100%;
  align-content:center;
  height: 100%;
  
}
.columnaA img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 90%;
}


.campoGrand{
  background-image: url('../assets/boxes/caja_grand.png');
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  text-align: center;
  height: var(--col-height);
  margin-block: 10px;
  width: 100%;
  height: 90px;
  color: white;
  size: 0px;
  /* background-color: red; */
  /* background-color: #ece0d5; */
  /* background-blend-mode: color-burn;Esta propiedad es innecesaria, ignorala. La uso simplemente para darle un tono distinto y más claro al fondo */
	background-position: center;
	background-size: cover;	
  
}

p{
  font-size: 28px;
  
}



</style>
