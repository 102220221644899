<template>
  <div class="end">
 

    <div class="columnas">
      
      <div class="columnaC">
        <img src="../assets/texts/Pleca.png" alt="">
      </div>

    </div>
  </div>
 
</template>

<script>
export default {
  name: 'GrandComponent',
  props: {
    msg: String
  }
}
</script>


<style scoped>
.end {
  background-image: url('../assets/fondo/Fondo_Final.png');
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  align-content: stretch;
  justify-content: center;
  height: var(--col-height);
  /* background-color: red; */
  /* background-color: #ece0d5; */
  /* background-blend-mode: color-burn;Esta propiedad es innecesaria, ignorala. La uso simplemente para darle un tono distinto y más claro al fondo */
	background-position: center;
	background-size: cover;	
}



.end img {
  width: 100%;      
  height: calc(100% - 50px); 
  object-fit: contain;  
}







.columnas {
  display: flex;
  align-items:center;
  height: auto;
}

.columna {
  flex: 1 1 100%;
  align-items: flex-start;
  /* margin: 40px; */
  
}

.columna img {
  display: block;
  margin: 0 auto;
  max-width: 50%;
  height: auto;
}



.columnaC {
  flex: 1 2 90%;
  height: 100%;
  align-content:center;
}

.columnaC img {
  display: block;
  margin: 0 auto;
  max-width: 80%;
  margin-block: 6%;
  /* height: auto; */
  height: 100%;
}

</style>
