<template>
  <div class="mini">
 
    <div class="columnas">
      
      <div class="columnaC">
        <img src="../assets/personajes/SPIN_alpha-3.png" alt="">
        <!-- <img src="../assets/personajes/SPIN_alpha-1.png" alt=""> -->
      </div>

      <div class="columna">
        
        <img src="../assets/texts/mini.png" alt=""> 
        <div class="campoMini">
          <p>{{ formatCurrency(minor) }}</p>
        </div >
        
      </div>

    </div>


  </div>
 
</template>

<script>
export default {
  name: 'GrandComponent',
  props: {
    minor: Number
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('es-Mx', { style: 'currency', currency: 'MXN' }).format(value)
    }
  },
}
</script>


<style scoped>
.mini {
  background-image: url('../assets/fondo/Conejo_fondo.png');
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  height: var(--col-height);
  /* background-color: red; */
  /* background-color: #ece0d5; */
  /* background-blend-mode: color-burn;Esta propiedad es innecesaria, ignorala. La uso simplemente para darle un tono distinto y más claro al fondo */
	background-position: center;
	background-size: cover;	
}



.mini img {
  width: 100%;      
  height: calc(100% - 50px); 
  object-fit: contain;  
}





.columnas {
  display: flex;
  align-items:center;
  height: 100%;
}

.columna {
  flex: 1 1 100%;
  align-items: flex-start;
  /* margin: 40px; */
  
}

.columna img {
  display: block;
  margin: 0 auto;
  max-width: 50%;
  height: auto;
}



.columnaC {
  flex: 1 2 90%;
  height: 100%;
  align-content:center;
}

.columnaC img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  /* height: auto; */
  height: 90%;
}


.campoMini{
  background-image: url('../assets/boxes/caja_mini.png');
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  text-align: center;
  height: var(--col-height);
  margin-block: 10px;
  width: 100%;
  height: 90px;
  color: white;
  size: 0px;
  /* background-color: red; */
  /* background-color: #ece0d5; */
  /* background-blend-mode: color-burn;Esta propiedad es innecesaria, ignorala. La uso simplemente para darle un tono distinto y más claro al fondo */
	background-position: center;
	background-size: cover;	
  
}

p{
  font-size: 28px;
  
}

</style>
