import { instanceLule } from './RestApi';

class ProgresiveService{



    getProgresive(request){
        return instanceLule.post("progressive/api/findProgressiveByIdRoom", request);
    }

    getAllProgresives(){
        return instanceLule.get("progressive/api/getInfoAllProgressives")
    }


}

export default new ProgresiveService();