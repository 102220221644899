var nodeenv = process.env.NODE_ENV || 'local';
var env = null;


var config = {
    local: require('./environments/local.js'),
    development: require('./environments/development.js'),
    production: require('./environments/production.js'),
}

var arrEnv = nodeenv.split(".");

if(arrEnv.length>0){
    console.log("env", arrEnv[0]);
    
    env = config[arrEnv[0]];
} else {
    env = config[nodeenv];
}


module.exports = env