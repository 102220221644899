<template>
  <div class="mayor">
 
  
    <div class="columnas">
      
      <div class="columna">

        <img src="../assets/texts/mayor.png" alt=""> 
        <div class="campoMayor">
          <p>{{ formatCurrency(mayor) }}</p>
        </div >
      </div>

      <div class="columnaM">
        <img src="../assets/personajes/HIT_alpha3.png" alt=""> 
      </div>

    </div>
    

  </div>
 
</template>

<script>
export default {
  name: 'GrandComponent',
  props: {
    mayor: Number
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('es-Mx', { style: 'currency', currency: 'MXN' }).format(value)
    }
  },
}
</script>


<style scoped>
.mayor {
  background-image: url('../assets/fondo/Hechicero_fondo.png');
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  height: var(--col-height);
  /* background-color: red; */
  /* background-color: #ece0d5; */
  /* background-blend-mode: color-burn;Esta propiedad es innecesaria, ignorala. La uso simplemente para darle un tono distinto y más claro al fondo */
	background-position: center;
	background-size: cover;	
}

.mayor img {
  width: 100%;      
  height: calc(100% - 50px); 
  object-fit: contain;  
}


.input-with-image {
  background-image: url('../assets/boxes/caja_mayor.png'); /* Ruta de la imagen */
  background-size: 200px 50px; /* Ajusta el tamaño de la imagen */
  background-position: 10px center; /* Ajusta la posición de la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  padding-left: 0px; /* Añade espacio a la izquierda para la imagen */
  width: 100%;
  height: 40px; /* Ajusta la altura del input */
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}










.columnas {
  display: flex;
  align-items:center;
  height: 100%;
}

.columna {
  flex: 1 1 100%;
  align-items:center;
  /* text-align: center; */
}

.columna img {
  display: block;
  margin: 0 auto;
  max-width: 70%;
  height: auto;
}

.columnaM {
  flex: 1 2 50%;
  align-content: center;
  height: 100%;
}
.columnaM img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 90%;
}





.campoMayor{
  background-image: url('../assets/boxes/caja_mayor.png');
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  text-align: center;
  height: var(--col-height);
  margin-block: 10px;
  width: 100%;
  height: 90px;
  color: white;
  size: 0px;
  /* background-color: red; */
  /* background-color: #ece0d5; */
  /* background-blend-mode: color-burn;Esta propiedad es innecesaria, ignorala. La uso simplemente para darle un tono distinto y más claro al fondo */
	background-position: center;
	background-size: cover;	
  
}

p{
  font-size: 28px;
  
}

</style>
