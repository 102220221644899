import axios from "axios";
import config from "../config";


const instanceLule = axios.create({
  // baseURL: `${config.IP_SERVICE_COORPO}:8940`,
  baseURL: `${config.IP_SERVICE_COORPO}`,
  headers: {
    "Content-Type": "application/json",
  }
}
);


export { instanceLule };