const salas =[
    {
        id:1,
        nombre:"Xalapa"
    },
    {
        id:14,
        nombre:"Queretaro"
    },
    {
        id:30,
        nombre:"Leon"
    },
    {
        id:36,
        nombre:"Mochis"
    },
    {
        id:45,
        nombre:"Interlomas"
    },
    {
        id:57,
        nombre:"Metepec"
    },
    {
        id:65,
        nombre:"SLRC"
    },
    {
        id:67,
        nombre:"Atizapan"
    },
    {
        id:76,
        nombre:"Veracruz"
    },
    {
        id:77,
        nombre:"Cordoba"
    },
    {
        id:78,
        nombre:"Tecamachalco"
    },
    {
        id:82,
        nombre:"Tlalnepantla"
    },
    {
        id:89,
        nombre:"Orizaba"
    },
    {
        id:96,
        nombre:"Celaya"
    },
    {
        id:98,
        nombre:"Pedregal"
    },
    {
        id:126,
        nombre:"Tuxpan"
    },
    {
        id:134,
        nombre:"SantaFe"
    },
    {
        id:136,
        nombre:"SLP"
    },
    {
        id:137,
        nombre:"Puebla"
    },
    {
        id:138,
        nombre:"Carmen"
    },
    {
        id:162,
        nombre:"Antea"
    }
    
];


export default salas;
 
// const idSala= (nombreSala:String)=>{
      //   return salas.find(sala => sala.nombre===nombreSala).id;
      // }