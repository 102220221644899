<template>
  <div class="start">
 
    <img src="../assets/logos/Logo TBDL@2x.png" alt="">
    <img src="../assets/texts/texto.png" alt=""> -->



    <!-- <div class="columnas">
      <div class="columnaC">
        <img src="../assets/logos/Logo TBDL@2x.png" alt="">
        <img src="../assets/texts/texto.png" alt="">
      </div>
    </div> -->
  
  
    
  </div>

</template>

<script>
export default {
  name: 'StartComponent',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.start {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  height: var(--col-height);
  /* background-color: red; */
  background-image: url('../assets/fondo/Fondo_inicial.png');
  /* background-color: #ece0d5; */
  background-blend-mode: color-burn;/*Esta propiedad es innecesaria, ignorala. La uso simplemente para darle un tono distinto y más claro al fondo*/
	background-position: center;
	background-size: cover;	
}


.start img {
  width: 100%;      
  height: calc(90% - 150px); 
  object-fit: contain;  
}



</style>
